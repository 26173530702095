import React from 'react';
import { useState, useEffect, useContext } from 'react';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell,TableSortLabel, TableContainer, TableHead, TableRow, Paper, Typography, Breadcrumbs, Button, Avatar } from '@material-ui/core';
import { Link } from "react-router-dom";
import DeleteIcon from '@material-ui/icons/Delete';

import { DeleteProduct } from '.././Store';
import { GetImagePath } from '.././Utils';

import { ShakiContext } from "../store/ShakiProvider";

function Products() {

	const { products, search, remote_config, getRemoteConf } = useContext(ShakiContext);

	const title = 'Produits';
	const classes = useStyles();

	const [list, setList] = useState([]);
	const [sort, setSort] = useState('asc');
	const [orderBy, setOrderBy] = useState('sku');
	const [packaging_types, setPackagingTypes] = useState({});


	useEffect(() => {

		var a = [];

		for (var i in products) {
			var product = products[i];
			if (product.sub_recipe) continue;
			if (product.disabled === true) continue;
			product.key_sort = (product.parent_sku === undefined ? product.sku : product.parent_sku) + (product.parent_sku === undefined ? 0 : 1)
			a.push(product);
		};

		setList(a);

	}, [products]);


	useEffect(() => {
		setPackagingTypes(getRemoteConf('packaging_types'));
	}, []);


	var del = (e) => {
		DeleteProduct(e);
	};


	var filtered_list = list.filter((row) => {

		if (search === '') return true;
		if (row.search_index.toLowerCase().search(search.toLowerCase()) !== -1) return true;

		return false;
	});


	return (
		<div>

			<div style={{ padding: 20 }}>

				<Breadcrumbs aria-label="breadcrumb">
					<Link to='/'>Dashboard</Link>
					<Typography>{title}</Typography>
				</Breadcrumbs>

				<h2>{title}</h2>
			</div>

			<TableContainer component={Paper}>
				<Table className={classes.table} aria-label="customized table">

					<TableHead>
						<TableRow>
							<StyledTableCell></StyledTableCell>
							<StyledTableCell><b>SKU</b></StyledTableCell>
							<StyledTableCell><b>EAN13</b></StyledTableCell>
							<StyledTableCell><b>Marque</b></StyledTableCell>
							<StyledTableCell><b>Nom</b></StyledTableCell>
							<StyledTableCell><b>Conditionnement</b></StyledTableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{filtered_list.sort((a, b) => a.key_sort > b.key_sort ? 1 : -1).map((row, i) => (
						<StyledTableRow key={row.sku}>

							<StyledTableCell >
								<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }} >
									{row.parent_sku !== undefined ?
										<div style={{ width: 30, height: 70, opacity: 0.5, marginRight: 2, borderRadius: 5 }} />
										:
										<div style={{ width: 5, height: 70, backgroundColor: '#AEEE95', opacity: 0.8, marginRight: 2, borderRadius: 5 }} />
									}
									<Avatar src={GetImagePath(row.images, 'main')} className={classes.large} style={{ width: 105, height: 70 }} variant='rounded' component='span'/>
								</div>
							</StyledTableCell>

							<StyledTableCell component="th" scope="row">

								<Link to={'/products/' + row.id}>
									{row.sku}
								</Link>

								<br />
								<div style={{ fontSize: 12 }}>{row.parent_sku}</div>

							</StyledTableCell>

							<StyledTableCell>{row.ean13}</StyledTableCell>

							<StyledTableCell>
								{row.disabled ? '🔴' : '' } {row.brand}
								<br/>
								<div style={{ fontSize: 12 }}>{row.category}</div>
							</StyledTableCell>

							<StyledTableCell>
								{row.name}<br />
								<div style={{ fontSize: 12 }}>{row.weight}g</div>
							</StyledTableCell>

							<StyledTableCell>
								{(row.packaging !== undefined && row.packaging.type !== undefined && row.packaging.type !== '' && packaging_types[row.packaging.type] !== undefined) ? packaging_types[row.packaging.type].name : ''}<br/>
								{row.packaging !== undefined && row.packaging.format !== '' ? row.packaging.format : '-'}<br/>
								{row.packaging !== undefined && row.packaging.back_format !== '' ? row.packaging.back_format : '-'}
							</StyledTableCell>

						</StyledTableRow>

						))}
					</TableBody>
				</Table>
			</TableContainer>

		</div>
	);
}

export default React.memo(Products);

const StyledTableCell = withStyles((theme) => ({
	head : {

	},
	body : {
		fontSize: 14,
	},
}))(TableCell);

const StyledTableSortLabel = withStyles((theme) => ({

}))(TableSortLabel);

const StyledTableRow = withStyles((theme) => ({
	root : {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

const useStyles = makeStyles({
	table: {
		minWidth: 700,
	},
	large: {
		width: 80,
		height: 80,
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
});
