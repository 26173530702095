import React from 'react';
import { useState, useEffect, useContext } from 'react';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore"

import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TablePagination, TableContainer, TableHead, TableRow, Paper, Typography, Breadcrumbs, Button, Avatar } from '@material-ui/core';
import { Link, useParams, useHistory } from "react-router-dom";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import FileCopyIcon from '@material-ui/icons/FileCopy';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import LuxonUtils from '@date-io/luxon';

import { ShakiContext } from "../store/ShakiProvider";
import { TimeContext } from "../store/TimeProvider";
import { ShopsContext } from "../store/ShopsProvider";
import { UsersContext } from "../store/UsersProvider";

import { GetTotalWeight, GetTotalPrices, GetOrderKey, WeekDays, GetLocalStorage, SetLocalStorage, DownloadFile } from '.././Utils';
import { AddOrder, DeleteOrder } from '.././Store';

import { DateTime } from "luxon";
import { GetImagePath } from '.././Utils';


function Orders(data) {

	const { user } = useContext(UsersContext);
	const { routes_config, search } = useContext(ShakiContext);
	const { day_prod } = useContext(TimeContext);
	const { shops, price_list, GetCurrentRateCard, GetCurrentPriceList, GetInvoice } = useContext(ShopsContext);

	var { shop_id } = useParams();
	var { include } = data;

	const today = GetLocalStorage('orders_selected_date') !== null ? DateTime.fromISO(GetLocalStorage('orders_selected_date')) : DateTime.fromISO(day_prod);

	if (shop_id === 'all') {

		var type = 'all';
		var title = 'Commandes de la semaine ' + today.toFormat('W')

	} else if (shop_id.match(/([0-9a-zA-Z]*_[0-9a-zA-Z]*)/) !== null) {

		var type = 'edi';
		var edi_reference = shop_id;
		var title = 'Commandes EDI ' + edi_reference;

	} else {

		var type = 'shop';
		var title = '';
	};


	var history = useHistory();
	const classes = useStyles();

	const [orders, setOrders] = useState([]);
	const [list, setList] = useState([]);

	const [selected_date, setSelectedDate] = useState(today);
	const [open_duplicate, setOpenDuplicate] = useState(false);
	const [open_delete, setOpenDelete] = useState(false);

	const [duplicate_obj, setDuplicateObj] = useState(false);
	const [duplicate_date, setDuplicateDate] = useState(DateTime.local());

	const [delete_obj, setDeleteObj] = useState(false);

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(200);


	useEffect(() => {

		if (shops === null) return;
		if (Object.values(price_list).length === 0) return;

		var treatment = (querySnapshot) => {

			if (querySnapshot === null) return;

			var list = [];
			querySnapshot.forEach((doc) => {

				var data = doc.data();
				data.shop = shops[data.shop_id];
				data.pricelist = GetCurrentPriceList(data.shop.pricelist_group_id, data.day);
				data.rate_card = GetCurrentRateCard(data.day);

				list.push(data);
			});

			list.sort((a, b) => {
				if (parseInt(a.day_prod) < parseInt(b.day_prod)) return 1;
				if (parseInt(a.day_prod) > parseInt(b.day_prod)) return -1;
				return 0;
			});

			setOrders(list);
		};

		if (type === 'all') {
			return firebase.firestore().collection('shaki_orders').where('day_prod', '==', parseInt(selected_date.toFormat('yyyyLLdd'))).onSnapshot(treatment);
		} else if (type === 'edi') {
			return firebase.firestore().collection('shaki_orders').where('edi_reference', '==', edi_reference).onSnapshot(treatment);
		} else if (shop_id !== undefined) {
			return firebase.firestore().collection('shaki_orders').where('shop_id', '==', shop_id).onSnapshot(treatment);
		}; //else {
			//return firebase.firestore().collection('shaki_orders').where('shop_id', '==', user_profile.shop_owner).onSnapshot(treatment);
		//}

	}, [shop_id, selected_date, shops, price_list]);


	useEffect(() => {

		var list = orders.filter((row) => {
			if (search === '') return true;
			if (shops[row.shop_id].name.toLowerCase().search(search.toLowerCase()) !== -1) return true;
			if (row.warehouse_id !== undefined && shops[row.warehouse_id].name.toLowerCase().search(search.toLowerCase()) !== -1) return true;

			if (search === 'edi' && row.edi_reference !== undefined) return true;
			if (row.edi_reference !== undefined && row.edi_reference.search(search) !== -1) return true;
			if (routes_config[row.tour] !== undefined && routes_config[row.tour].name.toLowerCase().search(search.toLowerCase()) !== -1) return true;
		});

		setList(list);

	}, [shops, orders, search]);


	const initDuplicate = (order) => {
		setDuplicateObj(order);
		setDuplicateDate(DateTime.fromISO(order.day_prod).setLocale('fr').plus({ days: 7 }))
		setOpenDuplicate(true);
	};


	const initDelete = (order_id) => {
		setDeleteObj(order_id)
		setOpenDelete(true);
	};


	const closeDuplicate = () => {
		setOpenDuplicate(false);
	};


	const closeDelete = () => {
		setOpenDelete(false);
	};


	const switchDay = (i) => {

		var diff = i - (parseInt(today.toFormat('c')) - 1);

		var new_date;
		if (diff === 0) new_date = today;
		if (diff > 0) new_date = today.plus({days : diff});
		if (diff < 0) new_date = today.minus({days : diff * -1});

		SetLocalStorage('orders_selected_date', new_date.toFormat('yyyyLLdd'));
		setSelectedDate(new_date);

		return true;
	};


	const handleDateChange = (date) => {

		SetLocalStorage('orders_selected_date', date.toFormat('yyyyLLdd'));
		setSelectedDate(date);

		return true;
	};


	const duplicate = () => {

		firebase.firestore().collection('shaki_orders').doc(duplicate_obj.id).get().then(doc => {

			var data = doc.data();

			// Override
			delete data.id;
			delete data.external_reference;
			delete data.edi_reference;
			delete data.last_modified_by;
			delete data.onfleet_task;
			delete data.__meta;
			delete data.created_by;
			delete data.delivery_slip_link;
			delete data.delivery;
			delete data.delivery_file;
			delete data.delivered;
			delete data.shop;
			delete data.tour;
			delete data.shift;
			//delete data.hour_delivery;
			//delete data.hour_dropoff;
			//delete data.delivery_national;
			//delete data.delivery_signature;
			//delete data.delivery_picture;
			//delete data.delivery_unsold;
			//delete data.delivery_hour;
			delete data.prepared_at;
			delete data.prepared;
			delete data.containers;

			delete data.rack;
			delete data.rack_case;
			delete data.rack_challenge;
			delete data.rack_challenge_answer;
			delete data.pallet;

			if (data.day_prod === data.day) {
				data.day_prod = parseInt(duplicate_date.toFormat('yyyyLLdd'));
				data.day = parseInt(duplicate_date.toFormat('yyyyLLdd'));
			} else {
				data.day_prod = parseInt(duplicate_date.toFormat('yyyyLLdd'));
				data.day = parseInt(duplicate_date.plus({ days: 1}).toFormat('yyyyLLdd'));
			};

			data.key = GetOrderKey();

			AddOrder(data, user, () => {
				closeDuplicate();
			});
		});
	};


	const countProduct = list => {
		var count = 0;
		for (var i in list) {
			count += list[i];
		}
		return count;
	};


	const totalProduct = list => {
		var total = 0;
		for (var i in list) {
			total += countProduct(list[i].products);
		}
		return total;
	};


	const totalWeight = list => {
		var total = 0;
		for (var i in list) {
			total += GetTotalWeight(list[i].products);
		}
		return total;
	};


	const totalHT = (row) => {
		return GetTotalPrices(row.rate_card, row.pricelist, row.delivered !== undefined ? row.delivered : row.products);
	};


	const downloadInvoice = (id) => {
		return GetInvoice(id, (data) => {
			DownloadFile(data.path)
		});
	};


	return (
		<div>

			<div style={{ padding: 20 }}>

				{(!include && shop_id !== undefined) &&
					<>
						<Breadcrumbs aria-label="breadcrumb">
							<Link to='/'>Accueil</Link>
							<Typography>{title}</Typography>
						</Breadcrumbs>
					</>
				}

				{title !== '' &&
				<h2 style={{ paddingBottom: 40 }}>{title}</h2>
				}

				<div style={{ width : '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Typography><b>{orders.length} commandes</b></Typography>
					</div>

					<div style={{ width: 500, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
						{type === 'all' &&
						WeekDays.map((day, i) => (
							<div key={'week_days_' + i} style={(parseInt(selected_date.toFormat('c')) - 1) === i ? {padding : 5, backgroundColor : '#000', color : '#FFF', borderRadius : 5} : {padding : 5, backgroundColor : '#F0F0F0', color : '#000', borderRadius : 5}} onClick={(e) => switchDay(i)}>
								{day}
							</div>
						))
						}
					</div>

					<div>

						{type === 'all' &&
							<MuiPickersUtilsProvider utils={LuxonUtils}>
								<KeyboardDatePicker
									id='production_date'
									disableToolbar
									variant='inline'
									format='dd/MM/yyyy'
									margin='normal'
									label='Date de production'
									value={selected_date}
									onChange={handleDateChange}
									KeyboardButtonProps={{
										'aria-label': 'change date'
									}}
								/>
							</MuiPickersUtilsProvider>
						}

						{(type === 'shop' && shop_id !== undefined) &&
							<Button component={Link} to={'/shops/' + shop_id + '/neworder'} variant='outlined' color='default'>
								Nouvelle commande
							</Button>
						}

						{(type === 'shop' && shop_id !== undefined ) &&
							<Button style={{ marginLeft: 10 }} href={'https://europe-west1-shakishaki-243509.cloudfunctions.net/pricelists-downloadOrderForm?shop_id=' + shop_id} variant='outlined' color='default'>
								Télécharger le bon de commande
							</Button>
						}

					</div>
				</div>
			</div>

			<TableContainer component={Paper}>
				<Table className={classes.table} aria-label="customized table">

					<TableHead>
						<TableRow>
							{type !== 'shop' &&
								<StyledTableCell></StyledTableCell>
							}
							<StyledTableCell>ID</StyledTableCell>
							{type !== 'shop' &&
								<StyledTableCell>Client</StyledTableCell>
							}
							<StyledTableCell>Date de production</StyledTableCell>
							<StyledTableCell>Date de livraison</StyledTableCell>
							<StyledTableCell>Tournée</StyledTableCell>
							<StyledTableCell align="right">Nb/produits</StyledTableCell>
							<StyledTableCell align="right">Poids</StyledTableCell>
							{type === 'shop' &&
								<StyledTableCell align="right">Montant H.T.</StyledTableCell>
							}
							<StyledTableCell align="right">Actions</StyledTableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (

						<TableRow key={row.id} className={row.predictive ? classes.tr_predictive : classes.tr}>

							{type !== 'shop' &&
								<StyledTableCell>
									{shops[row.shop_id].images !== undefined ?
										<Avatar
										src={GetImagePath(shops[row.shop_id].images, 'main')}
										style={{ width: 40, height: 40, border: (row.prepared_at !== undefined) ? '2px solid #00FF00' : '2px solid red'}}
										/>
									:
										<Avatar style={{ width: 40, height: 40 }}>{shops[row.shop_id].name.substr(0, 1)}</Avatar>
									}
								</StyledTableCell>
							}

							<StyledTableCell component="th" scope="row">
								<Link to={'/order/' + row.id}>{row.key}</Link>
								<br/>
								<div style={{ fontSize: 12 }}>{row.external_reference} {row.edi_reference !== undefined ? <>| EDI <Link to={'/orders/' + row.edi_reference}>{row.edi_reference}</Link></> : ''}</div>
							</StyledTableCell>

							{type !== 'shop' &&
							<StyledTableCell>
								<Link to={'/shops/' + shops[row.shop_id].id}>{shops[row.shop_id].name}</Link>
								{row.warehouse_id !== undefined &&
								<>
									<br/>
									<div style={{ fontSize: 12 }}>via {shops[row.warehouse_id].name}</div>
								</>
								}
							</StyledTableCell>
							}

							<StyledTableCell>{DateTime.fromISO(row.day_prod).setLocale('fr').toFormat('ccc dd LLL yyyy')}</StyledTableCell>

							<StyledTableCell>{DateTime.fromISO(row.day).setLocale('fr').toFormat('ccc dd LLL yyyy')}</StyledTableCell>

							<StyledTableCell>{routes_config[row.tour] !== undefined ? routes_config[row.tour].display_name : '' }<br />
								{row.rack !== undefined &&
									<span style={{ padding: 5, fontSize: 12, borderRadius: 10, color: 'white', backgroundColor: 'black', marginRight: 5 }}>{row.rack}{row.rack_case}</span>
								}
								{row.pallet_slot !== undefined &&
									<span style={{ padding: 5, fontSize: 12, borderRadius: 10, color: 'white', backgroundColor: 'black'}}>palette {row.pallet_slot}</span>
								}
							</StyledTableCell>

							<StyledTableCell align="right">{countProduct(row.products)}</StyledTableCell>

							<StyledTableCell align="right">{GetTotalWeight(row.products, row.id)}kg</StyledTableCell>

							{type === 'shop' &&
								<StyledTableCell align="right">{totalHT(row)}</StyledTableCell>
							}

							<StyledTableCell align="right">

								{(row.invoices !== undefined && Object.values(row.invoices).length > 0) &&
								<>
									{Object.entries(row.invoices).map((invoice, index) => (
									<Button key={invoice[0][0]} onClick={(e) => downloadInvoice(invoice[0])} color='primary' size='small' style={{ minWidth: 20 }} title={'Télécharger la facture'}>
										<GetAppIcon />
									</Button>
									))}
								</>
								}

								{(type === 'shop' && shop_id !== undefined) &&
								<Button onClick={(e) => initDuplicate({ id: row.id, key: row.key, day: row.day, day_prod: row.day_prod })} color='primary' size='small' style={{ minWidth: 20 }}>
									<FileCopyIcon />
								</Button>
								}

								<Button onClick={(e) => history.push('/order/' + row.id + '/edit')} color='primary' size='small' style={{ minWidth: 20 }}>
									<EditIcon />
								</Button>

								<Button onClick={(e) => initDelete({id : row.id, key : row.key, day : row.day, day_prod : row.day_prod})} color='primary' size='small' style={{ minWidth: 20 }} disabled={DateTime.local().toFormat('yyyyLLdd') > DateTime.fromISO(row.day_prod).toFormat('yyyyLLdd')}>
									<DeleteIcon />
								</Button>

							</StyledTableCell>

						</TableRow>
						))}

					</TableBody>

				</Table>

				<div style={{ display: 'flex', flexDirection: 'row', justifyContent : 'space-between', padding: 16, color: 'white', backgroundColor: 'darkslateblue' }}>
					<div></div>
					<div>
						<b>Nombre de produits</b>&nbsp;&nbsp;&nbsp;&nbsp;{totalProduct(list)} / <b>Poids total</b>&nbsp;&nbsp;&nbsp;&nbsp;{totalWeight(list)}kg
					</div>
				</div>

			</TableContainer>

			<TablePagination
				rowsPerPageOptions={[50, 100, 200]}
				component="div"
				count={list.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={(event, newPage) => {
					setPage(newPage);
				}}
				onRowsPerPageChange={(event) => {
					setRowsPerPage(parseInt(event.target.value, 10));
					setPage(0);
				}}
			/>

			<Dialog
				open={open_duplicate}
				onClose={closeDuplicate}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{'Dupliquer'}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Dupliquer la commande du {DateTime.fromISO(duplicate_obj.day_prod).toFormat('dd/LL/yyyy')} pour le:
						<div>
							<MuiPickersUtilsProvider utils={LuxonUtils}>
								<KeyboardDatePicker
									id='production_date'
									disableToolbar
									variant='inline'
									format='dd/MM/yyyy'
									margin='normal'
									disablePast={true}
									value={duplicate_date}
									onChange={(date) => {setDuplicateDate(date)}}
									KeyboardButtonProps={{
										'aria-label': 'change date'
									}}
									style={{ width: 200 }}
								/>
							</MuiPickersUtilsProvider>
						</div>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={closeDuplicate} color="primary">
						Annuler
					</Button>
					<Button onClick={duplicate} color="primary" autoFocus>
						Confirmer
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={open_delete}
				onClose={closeDelete}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{'Supprimer'}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
					Etes-vous sur de vouloir supprimer la commande {delete_obj.key} du {DateTime.fromISO(delete_obj.day_prod).toFormat('ccc dd LLL yyyy')}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={closeDelete} color="primary">
						Annuler
					</Button>
					<Button onClick={(e) => {DeleteOrder(delete_obj.id); closeDelete()}} color="primary" autoFocus>
						Confirmer
					</Button>
				</DialogActions>
			</Dialog>

		</div>
	);
}

export default React.memo(Orders);

const StyledTableCell = withStyles((theme) => ({
	head : {
		backgroundColor : theme.palette.common.black,
		color : theme.palette.common.white,
	},
	body : {
		fontSize: 14,
	},
}))(TableCell);


const useStyles = makeStyles({
	table: {
		minWidth: 700,
	},
	tr: {
		'&:nth-of-type(odd)': {
			backgroundColor: '#F5F5F5',
		}
	},
	tr_predictive: {
		'&:nth-of-type(2n+1)': {
			backgroundColor: '#EDF0E5',
		},
		'&:nth-of-type(2n)': {
			backgroundColor: '#DBE1CB',
		}
	}
});
